import React from "react"
import config from "../config.yaml"

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return(
            <footer>
                <br/><br/>
                <center>
                    <div>
                        <span>
                            {config.nombre}
                        </span> @ <a href={config.website} target="_blank" rel="noopener noreferrer">
                            jpdiez.ar
                        </a>
                    </div>
                    
                </center>
                <br/>
            </footer>
        )
    }
}